<!-- header -->
<template>
    <div class="header-container">
        <div class="header-top">
            <div class="header-top-c flex justify-s-b align-c color-666 font-12">
                <div class="flex align-c">
                    <router-link :to="{name: 'home'}" class="flex align-c">
                        <span class="home-icon mb-5"></span>
                        <span class="ml-5">汉坤实业首页</span>
                    </router-link>
                    <span v-if="$store.state.userInfo.tXiaoqu" class="ml-20">{{$store.state.userInfo.tXiaoqu}}</span>
                    <span v-if="$store.state.userInfo.id" class="ml-20 point" @click="logout">退出</span>
                    <span v-else class="ml-20 point" @click="login">登录</span>
                </div>
                <ul class="header-top-nav flex align-c">
                    <li>
                        <router-link :to="{name: 'myOrder'}">我的订单</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'cart'}">我的购物车</router-link>
                    </li>
                    <li>
                        <el-popover placement="bottom" trigger="hover">
                            <p class="font-14">电话：187-1112-3379</p>
                            <span slot="reference">联系客服</span>
                        </el-popover>
                    </li>
                    <li>
                        <el-popover placement="bottom" trigger="hover">
                            <img src="../../assets/images/qr_code.jpg" style="width: 160px; height:auto;">
                            <span slot="reference">小程序版</span>
                        </el-popover>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="!$route.meta.hideNav" class="header-search-cart flex justify-s-b align-c">
            <img src="../../assets/images/logo.png" class="logo">
            <div class="header-search-box flex">
                <input type="text" v-model="searchKey" autocomplete="off" 
                    placeholder="请输入商品名称搜索" class="search" @keyup.enter="toSearch">
                <div class="search-btn flex justify-c align-c" @click="toSearch">
                    <i class="el-icon-search"></i>
                </div>
            </div>
            <router-link :to="{name: 'cart'}" tag="div" class="cart-box flex justify-c align-c point">
                <i class="el-icon-shopping-cart-2 color-theme font-18"></i>
                <span class="color-666 ml-10">购物车</span>
                <span v-if="$store.state.cartTotal" class="badge ml-5">{{$store.state.cartTotal}}</span>
            </router-link>
        </div>
        <div v-if="!$route.meta.hideNav" class="menu-container">
            <div class="menu-container-c flex align-c">
                <div v-if="active===0" class="classify-tit flex justify-c align-c">全部商品分类</div>
                <ul class="flex">
                    <li class="menu point" :class="active===index ? 'color-theme' : ''"
                        v-for="(item, index) in menuList" :key="item.id"
                        @click="menuChange(index, item.pathName)">{{item.name}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/eventBus'
export default {
    name: 'Header',
    components: {},

    data() {
        return {
            active: 0,
            menuList: [{
                name: '商城首页',
                path: '/home',
                pathName: 'home'
            },
            {
                name: '限时秒杀',
                path: '/miaoSha',
                pathName: 'miaoSha'
            },
            {
                name: '在线下单',
                path: '/placeOrder',
                pathName: 'placeOrder'
            },
            {
                name: '积分商城',
                path: '/pointMall',
                pathName: 'pointMall'
            }
            ],
            searchKey: this.$route.query.searchKey ? decodeURIComponent(this.$route.query.searchKey) : ''
        };
    },

    watch: {
        $route() {
            this.setActive()
        },
    },

    created() {
        if(this.$store.state.userInfo.id) {
            this.$store.dispatch('GET_CART_TOTAL')
        }
        this.setActive()
    },

    mounted(){
        bus.$on('sendSearchKey', data => {
            this.searchKey = data || ''
        })
    },

    methods: {
        menuChange(index, name){
            if(this.$route.name != name) {
                this.active = index
                this.$router.push({ name: name })
            }
        },

        logout() {
            this.$https.get(this.$api.logout, { 
                mid: this.$store.state.userInfo.id
            }).then(async(res) => {
                await this.$router.push({ name: 'login' })
                this.$store.commit('LOGIN_OUT')
            });
        },

        login() {
            this.$router.push({ name: 'login' })
        },

        setActive() {
            this.active = ''
            this.menuList.forEach((item, i) => {
                if(item.pathName == this.$route.name)
                    this.active = i
            })
        },

        toSearch() {
            if(!this.searchKey) return
            this.$router.push({
                name: 'search',
                query: {
                    searchKey: encodeURIComponent(this.searchKey)
                }
            })
        }
    }
}
</script>
<style lang='scss' scoped>
    .header-top{
        background-color: #F1F1F1;
        .header-top-c{
            width: 1200px;
            height: 34px;
            margin: 0 auto;
            .home-icon{
                width: 14px;
                height: 14px;
                display: block;
                background: url(../../assets/images/sprite.png) no-repeat;
                background-position: -25px -84px;
            }
            .header-top-nav{
                li{
                    padding: 0 10px;
                    border-right: 1px solid #CCCCCC;
                    &:last-child{
                        border: none;
                    }
                    span{
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .header-search-cart{
        width: 1200px;
        padding: 25px 0 10px;
        margin: 0 auto;
        .logo{
            width: 196px;
            height: auto;
        }
        .header-search-box{
            border: 2px solid var(--theme-color);
            background: var(--theme-color);
            border-radius: 23px;
            overflow: hidden;
            position: relative;
            .search{
                padding-left: 22px;
                width: 440px;
                height: 42px;
                line-height: 42px;
                border: none;
                border-radius: 21px 0px 0px 21px;
                outline: 0;
            }
            .search-btn{
                width: 88px;
                height: 42px;
                cursor: pointer;
                i{
                    color: #fff;
                    font-size: 28px;
                }
            }
        }
        .cart-box{
            width: 160px;
            height: 35px;
            margin-right: 36px;
            border: 1px solid #0C3890;
            border-radius: 18px;
            .badge{
                display: block;
                height: 18px;
                line-height: 18px;
                color: #fff;
                font-size: 12px;
                padding: 0 6px;
                text-align: center;
                white-space: nowrap;
                border-radius: 9px;
                background-color: var(--theme-color);
            }
        }
    }
    .menu-container{
        border-bottom: 3px solid var(--theme-color);
        .menu-container-c{
            width: 1200px;
            height: 45px;
            margin: 0 auto;
            .classify-tit{
                width: 256px;
                height: 45px;
                color: #fff;
                font-size: 16px;
                background-color: var(--theme-color);
                border-radius: 5px 5px 0px 0px;
                margin-right: 37px;
            }
            .menu{
                font-size: 16px;
                font-weight: bold;
                margin-right: 30px;
            }
        }
    }
</style>